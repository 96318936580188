import { apply_is_added, apply_is_fav } from "@/libs/eventBus.js"
import { openLoginRegistrationPopup } from "@/libs/helpers.js"

function addEventListenerToElements(selector, eventClass, eventType, callback) {
    const elements = document.querySelectorAll(selector + `:not(.${eventClass})`)
    elements.forEach((element) => {
        element.classList.add(eventClass)
        element.addEventListener(eventType, callback, { passive: true })
    })
}

function handlePackageClick(event, eventName) {
    event.preventDefault()
    const containerIcon = event.target.closest(".button-add-package")
    if (containerIcon) {
        const packageId = containerIcon.className.match(/add-icon-package-(\d+)/)?.[1]
        if (packageId) {
            window.eventBus.$emit(eventName, "package", packageId)
        }
    }
}

function handleFavClick(event, eventName) {
    event.preventDefault()
    const containerIcon = event.target.closest(".container-icon")
    if (containerIcon) {
        const packageId =
            containerIcon.className.match(/fav-icon-package-(\d+)/)?.[1] ?? containerIcon.id?.replace("package-id-", "")
        if (packageId) {
            window.eventBus.$emit(eventName, "package", packageId)
        }
    }
}

export function buttonAddPackageFn(selector = "") {
    apply_is_added()
    addEventListenerToElements(selector + ".button-add-package", "event-added", "click", (event) =>
        handlePackageClick(event, "apply_is_added"),
    )
}

export function buttonAddPackage() {
    apply_is_added()
    window.addEventListener("DOMContentLoaded", () => buttonAddPackageFn(), { passive: true })
}

export function buttonFavIconPackage() {
    apply_is_fav()
    window.addEventListener(
        "DOMContentLoaded",
        () => {
            addEventListenerToElements(".button-fav", "event-added", "click", (event) =>
                handleFavClick(event, "apply_is_fav"),
            )
        },
        { passive: true },
    )
}

export function buttonFavIconPackageNotLogin() {
    window.addEventListener(
        "DOMContentLoaded",
        () => {
            const elements = document.querySelectorAll(".button-fav-not-login:not(.event-added)")
            elements.forEach((element) => {
                element.classList.add("event-added")
                const close = document.querySelector(".tutkitpopup612 .icon-close-dark")
                close.addEventListener(
                    "click",
                    (event) => {
                        event.preventDefault()
                        document.querySelector(".tutkitpopup612").classList.remove("popup-open")
                    },
                    { passive: true },
                )

                const authButtons = document.querySelectorAll(".button-bar button")
                authButtons.forEach((item, key) => {
                    item.addEventListener(
                        "click",
                        (e) => {
                            e.preventDefault()
                            document.querySelector(".tutkitpopup612").classList.remove("popup-open")
                            openLoginRegistrationPopup(key === 0 ? "registration" : "")
                        },
                        { passive: true },
                    )
                })

                element.addEventListener(
                    "click",
                    (event) => {
                        const containerIcon = event.target.closest(".container-icon")
                        const containerBlock = containerIcon.closest(".line.rate-now")
                        const popUp = containerBlock.nextElementSibling
                        if (containerIcon) {
                            const packageId =
                                containerIcon.className.match(/fav-icon-package-(\d+)/)?.[1] ??
                                containerIcon.id?.replace("package-id-", "")
                            if (packageId) {
                                popUp.classList.toggle("popup-open")
                            }
                        }
                    },
                    { passive: true },
                )
            })
        },
        { passive: true },
    )
}

export function buttonAddContentFn(selector = "") {
    apply_is_added()
    addEventListenerToElements(selector + ".button-add-content", "event-added", "click", (event) => {
        event.preventDefault()
        const containerIcon = event.target.closest(".button-add-content")
        if (containerIcon) {
            const contentId = containerIcon.className.match(/add-icon-content-(\d+)/)?.[1] ?? containerIcon.dataset.id
            if (contentId) {
                window.eventBus.$emit("apply_is_added", "content", contentId)
            }
        }
    })
}

export function buttonAddContent() {
    apply_is_added()
    window.addEventListener("DOMContentLoaded", () => buttonAddContentFn(), { passive: true })
}

export function buttonAddHelpVideo() {
    apply_is_added()
    window.addEventListener(
        "DOMContentLoaded",
        () => {
            addEventListenerToElements(".button-add-help-video", "event-added", "click", (event) => {
                event.preventDefault()
                const containerIcon = event.target.closest(".button-add-help-video")
                if (containerIcon) {
                    const contentId =
                        containerIcon.className.match(/add-icon-content-(\d+)/)?.[1] ?? containerIcon.dataset.id
                    if (contentId) {
                        window.eventBus.$emit("apply_is_added", "help-video", contentId)
                    }
                }
            })
        },
        { passive: true },
    )
}
